import ServiceHandler from '../services/service-handler';
export var formatPoints = function (points) { return points === null || points === void 0 ? void 0 : points.toLocaleString(ServiceHandler.ConfigService.LANGUAGE_CODE).replace(/ /g, ' '); };
export var formatMoney = function (value) {
    return (Math.round(+value * 100) / 100)
        .toLocaleString(ServiceHandler.ConfigService.LANGUAGE_CODE, { style: 'currency', currency: 'CAN' })
        .replace('CAN', '');
};
export var formatDate = function (date) {
    return new Date(date).toLocaleDateString(ServiceHandler.ConfigService.LANGUAGE_COUNTRY_CODE, {
        timeZone: 'UTC',
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
    });
};
export var formatLongDate = function (date) {
    return new Date(date).toLocaleDateString(ServiceHandler.ConfigService.LANGUAGE_COUNTRY_CODE, {
        timeZone: 'UTC',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });
};
export var formatDateYearOnly = function (date) {
    return new Date(date).toLocaleDateString(ServiceHandler.ConfigService.LANGUAGE_COUNTRY_CODE, {
        timeZone: 'UTC',
        year: 'numeric',
    });
};
export var formatQuantity = function (quantity) { return parseInt(quantity).toLocaleString(ServiceHandler.ConfigService.LANGUAGE_CODE); };
