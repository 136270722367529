export var SERVICE_CODE;
(function (SERVICE_CODE) {
    SERVICE_CODE[SERVICE_CODE["TRANSACTION_SERVICE"] = 1] = "TRANSACTION_SERVICE";
    SERVICE_CODE[SERVICE_CODE["POINTS_SERVICE"] = 2] = "POINTS_SERVICE";
    SERVICE_CODE[SERVICE_CODE["MESSAGE_SERVICE"] = 3] = "MESSAGE_SERVICE";
    SERVICE_CODE[SERVICE_CODE["USER_SERVICE"] = 4] = "USER_SERVICE";
    SERVICE_CODE[SERVICE_CODE["VEHICLES_SERVICE"] = 5] = "VEHICLES_SERVICE";
    SERVICE_CODE[SERVICE_CODE["DEALER_LOOKUP"] = 6] = "DEALER_LOOKUP";
    SERVICE_CODE[SERVICE_CODE["REWARDS_HISTORY_SERVICE"] = 7] = "REWARDS_HISTORY_SERVICE";
    SERVICE_CODE[SERVICE_CODE["REWARDS_SERVICE"] = 8] = "REWARDS_SERVICE";
    SERVICE_CODE[SERVICE_CODE["OFFERS_SERVICE"] = 11] = "OFFERS_SERVICE";
    SERVICE_CODE[SERVICE_CODE["POINTS_ELIGIBLE_SERVICE"] = 12] = "POINTS_ELIGIBLE_SERVICE";
    SERVICE_CODE[SERVICE_CODE["TRANSACTION_SERVICE_2"] = 13] = "TRANSACTION_SERVICE_2";
    SERVICE_CODE[SERVICE_CODE["FORDPAY_TOKEN"] = 14] = "FORDPAY_TOKEN";
    SERVICE_CODE[SERVICE_CODE["ADDRESS_LOOKUP"] = 15] = "ADDRESS_LOOKUP";
    SERVICE_CODE[SERVICE_CODE["ORDER_HISTORY"] = 16] = "ORDER_HISTORY";
    SERVICE_CODE[SERVICE_CODE["ORDER_HISTORY_2"] = 17] = "ORDER_HISTORY_2";
    SERVICE_CODE[SERVICE_CODE["TAX_CALCULATION"] = 18] = "TAX_CALCULATION";
    SERVICE_CODE[SERVICE_CODE["ACTIVATE"] = 19] = "ACTIVATE";
})(SERVICE_CODE || (SERVICE_CODE = {}));
