import React, { useContext, useEffect, useState } from 'react';
import { useContent } from '../../../hooks/use-content';
import Breadcrumbs from '../../../components/common/breadcrumbs/breadcrumbs';
import { ActivityIndicator } from '../../../components/common/activity-indicator/activity-indicator';
import { RewardsContext } from '../../../context/rewards-context';
import { useLocation } from 'react-router-dom';
import styles from './surprise-delight-view.module.scss';
import { RewardGroup } from '../rewards-helper-components';
import { DangerousHtml } from '../../../components/common/dangerous-html/dangerous-html';
var SurpriseAndDelightView = function () {
    var _a = useContent("v2/rewards-dashboard")[0], content = _a.content, getValueByTitle = _a.getValueByTitle;
    var _b = useContext(RewardsContext)[0], rewards = _b.rewards, categories = _b.categories, fetchSDReward = _b.fetchSDReward;
    var _c = useState({}), category = _c[0], setCategory = _c[1];
    var searchParams = new URLSearchParams(useLocation().search);
    var categoryCode = searchParams.get('ref');
    var breadcrumbs = [
        {
            name: 'breadcrumb-3',
        },
    ];
    useEffect(function () {
        setCategory(categories === null || categories === void 0 ? void 0 : categories.find(function (cat) { return cat.code == categoryCode; }));
        if (categories && rewards && !rewards.find(function (rwrd) { return rwrd.dashboardCategoryCode.toLowerCase() == categoryCode.toLowerCase(); })) {
            fetchSDReward(categoryCode);
        }
    }, [rewards, categories]);
    var filteredRewards = rewards === null || rewards === void 0 ? void 0 : rewards.filter(function (rwrd) { return rwrd.dashboardCategoryCode.toLowerCase() == categoryCode.toLowerCase(); });
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: styles.breadcrumbContainer },
            React.createElement(Breadcrumbs, { breadcrumbs: breadcrumbs, getValueByTitle: getValueByTitle })),
        content && filteredRewards ? (React.createElement("div", { className: styles.rewardContainer },
            React.createElement("div", { className: styles.heroTitle },
                React.createElement(DangerousHtml, { html: (category === null || category === void 0 ? void 0 : category.header) || 'Choose an Exclusive Reward' })),
            (category === null || category === void 0 ? void 0 : category.subheader) && (React.createElement("div", { className: styles.heroSubTitle },
                React.createElement(DangerousHtml, { html: category.subheader }))),
            React.createElement("div", { className: styles.rewardGroup },
                React.createElement("div", { className: styles.subHeader },
                    React.createElement("span", null,
                        filteredRewards.length,
                        " Results")),
                React.createElement(RewardGroup, { rewardGroup: filteredRewards, desktopCTA: getValueByTitle('desktop-details-cta'), mobileCTA: getValueByTitle('mobile-details-cta'), parameters: searchParams })))) : (React.createElement(ActivityIndicator, null))));
};
export default SurpriseAndDelightView;
