import { isMobile } from '../mobile-bridge/mobile-bridge';
var ConfigService = /** @class */ (function () {
    function ConfigService() {
    }
    ConfigService.prototype.initDataAttributes = function () {
        var _a, _b;
        var rootElement = document.querySelectorAll('[data-loyalty-script]')[0];
        this.OWNER_APP_ID = (rootElement === null || rootElement === void 0 ? void 0 : rootElement.getAttribute('data-app-id')) || process.env.REACT_APP_OWNER_APP_ID;
        this.LPS_BASE_URL = (rootElement === null || rootElement === void 0 ? void 0 : rootElement.getAttribute('data-lps-base-url')) || process.env.REACT_APP_LPS_BASE_URL;
        this.DSL_BASE_URL = (rootElement === null || rootElement === void 0 ? void 0 : rootElement.getAttribute('data-dsl-base-url')) || process.env.REACT_APP_DSL_BASE_URL;
        this.FPS_BASE_URL = process.env.FPS_BASE_URL || 'https://na.fps.ford.com';
        this.AEM_BASE_URL = (rootElement === null || rootElement === void 0 ? void 0 : rootElement.getAttribute('data-aem-base-url')) || process.env.REACT_APP_AEM_BASE_URL;
        this.FORDPAY_WIDGET_URL = process.env.FORDPAY_WIDGET_URL;
        this.PROGRAM_COUNTRY = (_a = ((rootElement === null || rootElement === void 0 ? void 0 : rootElement.getAttribute('data-program-country')) || process.env.REACT_APP_PROGRAM_COUNTRY)) === null || _a === void 0 ? void 0 : _a.toUpperCase();
        this.AMPLITUDE_API_KEY = process.env.AMPLITUDE_API_KEY || '0eb42ca51c84f5bfe35ee635860b2fc4';
        this.AMPLITUDE_EXPERIMENT_API_KEY = process.env.AMPLITUDE_EXPERIMENT_API_KEY || 'client-nEsMJI1dwioMX30jZ8RDlfuoBhPrhV4U';
        this.APP_BASEPATH = isMobile() && !window['fma'] ? '' : (rootElement === null || rootElement === void 0 ? void 0 : rootElement.getAttribute('data-app-basepath')) || process.env.REACT_APP_BASEPATH;
        this.env = (_b = (process.env.REACT_ENV || 'stage')) === null || _b === void 0 ? void 0 : _b.toLowerCase();
        this.DEPLOYMENT_ENV = process.env.REACT_APP_FMA_BUNDLE_ENV;
        this.LANGUAGE_COUNTRY_CODE = "".concat((rootElement === null || rootElement === void 0 ? void 0 : rootElement.getAttribute('data-language-code').substr(0, 2)) || process.env.REACT_APP_LANGUAGE_CODE, "-ca");
        this.COUNTRY_CODE = 'ca';
        var languages = ['en-ca', 'fr-ca'];
        if (!languages.includes(this.LANGUAGE_COUNTRY_CODE))
            this.LANGUAGE_COUNTRY_CODE = languages[0];
        this.LANGUAGE_CODE = this.LANGUAGE_COUNTRY_CODE.split('-')[0];
        this.BRAND_ID = process.env.REACT_APP_BRAND_ID; // 'fpr' or 'lar'
        this.BRAND_NAME = process.env.REACT_APP_BRAND_ID === 'fpr' ? 'ford' : 'lincoln';
        this.REWARD_PROGRAM = process.env.REACT_APP_BRAND_ID === 'fpr' ? 'F' : 'L';
        this.isUSA = this.PROGRAM_COUNTRY === 'US';
        this.isCAN = this.PROGRAM_COUNTRY === 'CAN';
        this.isEnglish = this.LANGUAGE_CODE === 'en';
        this.isFrench = this.LANGUAGE_CODE === 'fr';
        this.isLAR = this.BRAND_ID === 'lar';
        this.isFPR = this.BRAND_ID === 'fpr';
        this.LANGUAGE_COUNTRY = this.LANGUAGE_CODE + "-" + this.PROGRAM_COUNTRY.slice(0, 2);
    };
    return ConfigService;
}());
export default ConfigService;
