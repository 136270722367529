import React from 'react';
import { PointsHeroContextProvider } from './points-hero-context';
import { APIHelperContextProvider } from './api-helper-context';
import { CountryEligibilityContextProvider } from './country-eligibilty-context';
import { ParticipatingDealerContextProvider } from './paricipating-dealer-context';
import { RewardsContextProvider } from './rewards-context';
import { FeatureFlagContextProvider } from './feature-flag-context';
import { UserProfileContextProvider } from './user-profile-context';
export var ContextProviders = function (_a) {
    var children = _a.children;
    return (React.createElement(APIHelperContextProvider, null,
        React.createElement(FeatureFlagContextProvider, null,
            React.createElement(PointsHeroContextProvider, null,
                React.createElement(UserProfileContextProvider, null,
                    React.createElement(CountryEligibilityContextProvider, null,
                        React.createElement(ParticipatingDealerContextProvider, null,
                            React.createElement(RewardsContextProvider, null, children))))))));
};
